import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Первое присваивание',
    text: 'Дано двузначное число \\(a\\). Запишите десятки и единицы в две новые переменные. Выведите сумму и произведение этих переменных через пробел',
    note: null,
    input: '\\(a\\) - целое положительное двузначное число',
    output: 'Сумма и произведение цифр',
    examples: [
      {
        input: '38',
        output: '11 24'
      },
      {
        input: '12',
        output: '3 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сложение и вычитание с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала увеличьте значение \\(a\\) на 2, затем уменьшите \\(b\\) на \\(a\\). Выведите получившиеся значения',
    note: 'Новые переменных не создаём, а изменяем те, которые есть. Используем += и -=',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '7 2'
      },
      {
        input: '-1, 0',
        output: '1 -1'
      },
      {
        input: '-4, -6',
        output: '-2 -4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Умножение и деление с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала уменьшите значение \\(a\\) в 2 раза, используя целочисленное деление, затем увеличьте \\(b\\) в \\(a\\) раз. После этого уменьшите \\(a\\) в 2 раза, используя обычное деление. Выведите получившиеся значения',
    note: 'Новые переменных не создаём, а изменяем те, которые есть. Используем //=, /= и *=',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '1.0 18'
      },
      {
        input: '-1, 0',
        output: '-0.5 0'
      },
      {
        input: '-4, -6',
        output: '-1.0 12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Остаток и степень с присваиванием',
    text: 'Даны числа \\(a\\) и \\(b\\). Сначала перезапишите значение \\(a\\) на остаток от деления на 3, затем возведите \\(b\\) в степень \\(a\\), записав результат в \\(b\\). Выведите получившиеся значения',
    note: 'Новые переменных не создаём, а изменяем те, которые есть. Используем %= и **=',
    input: '\\(a\\), \\(b\\) - целые положительные',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 9',
        output: '2 81'
      },
      {
        input: '1, 7',
        output: '1 7'
      },
      {
        input: '6, 10',
        output: '0 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Гипотенуза и периметр',
    text: 'Даны катеты прямоугольного треугольника \\(a\\) и \\(b\\). Найти его гипотенузу и периметр. \\(c = \\sqrt{a^2 + b^2}, P = a + b + c\\).',
    note: 'Обратите внимание, что для расчёта периметра нам нужна гипотенуза, которую нужно вычислить. Таким образом, нужно сначала создать переменную \\(c\\), записав в неё значение выражения \\(\\sqrt{a^2 + b^2}\\), а затем использовать эту переменную.',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Гипотенуза и периметр через пробел',
    examples: [
      {
        input: '3, 4',
        output: '5.0 12.0'
      },
      {
        input: '5, 7',
        output: '8.602325267042627 20.602325267042627'
      }
    ]
  },
  {
    component: Simple,
    name: 'Арифметический обмен',
    text: 'Даны числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\), не используя новых переменных',
    note: 'Это можно сделать с помощью сложений и вычитаний, перезаписывая существующие переменные. Данный способ обмена применим только к числам',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен через третью переменную',
    text: 'Даны два числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\). Для обмена нужно использовать третью переменную',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен списочным присваиванием',
    text: 'Даны два числа \\(a\\) и \\(b\\). Нужно обменять значения переменных таким образом, чтобы число, которое изначально было в переменной \\(a\\), оказалось в переменной \\(b\\), а число, которое изначально было в \\(b\\), оказалось в \\(a\\). Для обмена нужно использовать списочное присваивание',
    note: 'Списочное присваивание - это вариант записи присваивания сразу в несколько переменных. Например: a, b = 10, 20. Таким образом в a попадёт 10, а в b 20',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Новые значения \\(a\\) и \\(b\\) через пробел',
    examples: [
      {
        input: '5, 7',
        output: '7 5'
      },
      {
        input: '-5, 3',
        output: '3 -5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тройной обмен через переменную',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Нужно обменять значения переменных таким образом, чтобы значения сдвинулись влево, а первое число стало последним. То есть, было \\(a\\), \\(b\\), \\(c\\), а должно стать \\(b\\), \\(c\\), \\(a\\). Для обмена использовать дополнительную переменную',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\) через пробел',
    examples: [
      {
        input: '1, 4, 7',
        output: '4 7 1'
      },
      {
        input: '10, 15, 20',
        output: '15 20 10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Тройной обмен списочным присваиванием',
    text: 'Даны три числа \\(a\\), \\(b\\) и \\(c\\). Нужно обменять значения переменных таким образом, чтобы значения сдвинулись влево, а первое число стало последним. То есть, было \\(a\\), \\(b\\), \\(c\\), а должно стать \\(b\\), \\(c\\), \\(a\\). Для обмена использовать списочное присваивание',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые',
    output: 'Новые значения \\(a\\), \\(b\\) и \\(c\\) через пробел',
    examples: [
      {
        input: '1, 4, 7',
        output: '4 7 1'
      },
      {
        input: '10, 15, 20',
        output: '15 20 10'
      }
    ]
  },
  {
    component: Simple,
    name: '2a, 4a',
    text: 'Дано число \\(a\\). Вывести \\(a * 2\\) и \\(a * 4\\), соблюдая ограничения:<ul><li>из арифметических действий можно применять только сложение</li><li>допускается не более двух сложений на задачу</li><li>нельзя создавать дополнительные переменные</li>',
    note: 'Например, получить \\(2 * 2\\) и \\(2 * 4\\) за два сложения можно так: \\(2 + 2 = 4\\); \\(4 + 4 = 8\\)',
    input: '\\(a\\) - целое положительное число',
    output: '\\(a * 2\\) и \\(a * 4\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '2\n4'
      },
      {
        input: '2',
        output: '4\n8'
      },
      {
        input: '5',
        output: '10\n20'
      }
    ]
  },
  {
    component: Simple,
    name: 'a ** 2, a ** 4, a ** 8',
    text: 'Дано число \\(a\\). Вывести \\(a^2\\), \\(a^4\\) и \\(a^8\\), соблюдая ограничения:<ul><li>из арифметических действий можно применять только умножение</li><li>допускается не более трёх умножений на задачу</li><li>нельзя создавать дополнительные переменные</li>',
    note: 'Например, получить \\(2^2\\), \\(2^4\\) и \\(2^8\\) за три умножения можно так: \\(2 * 2 = 4\\); \\(4 * 4 = 16\\); \\(16 * 16 = 256\\)',
    input: '\\(a\\) - целое положительное число',
    output: '\\(a^2\\), \\(a^4\\) и \\(a^8\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '1\n1\n1'
      },
      {
        input: '2',
        output: '4\n16\n256'
      },
      {
        input: '10',
        output: '100\n10000\n100000000'
      }
    ]
  },
  {
    component: Simple,
    name: '3a, 6a, 18a',
    text: 'Дано число \\(a\\). Вывести \\(a * 3\\), \\(a * 6\\) и \\(a * 18\\), соблюдая ограничения:<ul><li>из арифметических действий можно применять только сложение</li><li>допускается не более пяти сложений на задачу</li><li>нельзя создавать дополнительные переменные</li>',
    note: null,
    input: '\\(a\\) - целое положительное число',
    output: '\\(a * 3\\), \\(a * 6\\) и \\(a * 18\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '3\n6\n18'
      },
      {
        input: '5',
        output: '15\n30\n90'
      }
    ]
  },
  {
    component: Simple,
    name: '3a, 5a, 7a',
    text: 'Дано число \\(a\\). Вывести \\(a * 3\\), \\(a * 5\\) и \\(a * 7\\), соблюдая ограничения:<ul><li>из арифметических действий можно применять только сложение</li><li>допускается не более четырёх сложений на задачу</li><li>можно использовать только одну дополнительную переменную</li>',
    note: null,
    input: '\\(a\\) - целое положительное число',
    output: '\\(a * 3\\), \\(a * 5\\) и \\(a * 7\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '3\n5\n7'
      },
      {
        input: '5',
        output: '15\n25\n35'
      }
    ]
  },
  {
    component: Simple,
    name: '3a, 7a, 9a',
    text: 'Дано число \\(a\\). Вывести \\(a * 3\\), \\(a * 7\\) и \\(a * 9\\), соблюдая ограничения:<ul><li>из арифметических действий можно применять только сложение</li><li>допускается не более пяти сложений на задачу</li><li>можно использовать только одну дополнительную переменную</li>',
    note: null,
    input: '\\(a\\) - целое положительное число',
    output: '\\(a * 3\\), \\(a * 7\\) и \\(a * 9\\) на разных строках',
    examples: [
      {
        input: '1',
        output: '3\n7\n9'
      },
      {
        input: '5',
        output: '15\n35\n45'
      }
    ]
  }
]
